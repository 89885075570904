import { Moment } from "moment";
import * as React from "react";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { SufficiencyOverview } from "../../../sufficiency";
import ApprovalState from "./components/ApprovalState";
import LastUpdateDate from "./components/LastUpdateDate";
import Path from "./components/Path";
import Title from "./components/Title";
import { ApprovalStates } from "../Product/types";

export type ProductSummaryClasses = "container" | "summary" | "sufficiency";

const styles = (stiboTheme): StyleRules<ProductSummaryClasses> =>
  createStyles({
    container: {
      clear: "both",
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontSize: "12px",
      backgroundColor: "#FFFFFF",
      color: stiboTheme.palette.black[300],
      padding: "32px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    summary: {
      marginRight: "24px"
    },
    sufficiency: {
      alignSelf: "flex-end",
      marginTop: "24px"
    }
  });

interface ProductSummaryProps {
  title: string;
  id: string;
  path: string;
  currentRevision: string;
  approvalState: ApprovalStates;
  approvalDate?: Moment;
  lastUpdateDate?: Moment;
  hasSufficiencyLicence: boolean;
  className?: string;
}

class ProductSummary extends React.Component<ProductSummaryProps & { isUpdating?: boolean } & WithStiboStyles<ProductSummaryClasses>> {
  render() {
    const { title, id, currentRevision, approvalState, approvalDate, lastUpdateDate, classes, path, className, isUpdating, hasSufficiencyLicence } = this.props;

    return (
      <section className={className || classes.container}>
        <div className={classes.summary}>
          <Path {...{ path } as any} />
          <Title>{title}</Title>
          <div>
            {id} · <ApprovalState approvalState={approvalState} approvalDate={approvalDate} /> ·{" "}
            <span id={"ProductSummaryCurrentRevision"}>{currentRevision}</span>
            {lastUpdateDate && " · "}
            {lastUpdateDate && <LastUpdateDate lastUpdateDate={lastUpdateDate} />}
          </div>
        </div>
        {hasSufficiencyLicence && <SufficiencyOverview className={classes.sufficiency} isUpdating={isUpdating} />}
      </section>
    );
  }
}

export default withStiboStyles(styles)(ProductSummary);
