import { RichTextProvider, RichTextFn } from "@stibo/value-components";
import * as React from "react";
import ProductErrors from "../Errors/ProductErrors";
import { BridgeCallbacksProps } from "../ProductDetailsScreen";
import ProductSummary from "../ProductSummary/ProductSummary";
import Spinner from "../Spinner/Spinner";
import { AppConfig } from "../types";
import ProductAttributesEditor, { ScrollPosition } from "./productAttributesEditor/ProductAttributesEditor";
import { IProductEditorStore } from "./productEditorStore";
import { useRichText } from "./useRichText";
import { useState } from "react";
import { useObserver, observer } from "mobx-react";
import Overlay from "../Spinner/Overlay";
import { IProduct } from "../Product/types";

type Ref = React.RefObject<any> | null;
const SectionRef = React.createContext<Ref>(null);
const TableOfContentRef = React.createContext<Ref>(null);

export const useSectionRef = (): Ref => React.useContext<Ref>(SectionRef);
export const useTableOfContentContainerRef = (): Ref => React.useContext<Ref>(TableOfContentRef);

export interface ProductEditorModalContentProps {
  config: AppConfig;
  bridgeCallbacks?: BridgeCallbacksProps;
  store: IProductEditorStore;
}

export const ProductEditorModalContent: React.FC<ProductEditorModalContentProps> = props => {
  const { bridgeCallbacks: bridgeCallbacksProp, config, store } = props;
  const container = React.useRef<HTMLDivElement>(null);
  const tableOfContentContainer = React.useRef<HTMLDivElement>(null);
  const [bridgeCallbacks] = useState<BridgeCallbacksProps | undefined>(bridgeCallbacksProp);
  store.rememberScrollPosition.setEditorContainer(container);
  store.rememberScrollPosition.setTableOfContentContainer(tableOfContentContainer);

  const richTextFn = useRichText();

  return useObserver(() => {
    const { product, isSaving, isProductLoading } = props.store;
    const isLoadingFirstTime = isProductLoading && isSaving === false;

    if (isLoadingFirstTime) {
      return <Spinner withBackground />;
    }
    if (product) {
      return (
        <ProductErrors stepId={config.stepId} nodeType={config.nodeType} isEmptyProduct={false} isProductNotFound={product ? false : true}>
          {product && (
            <Editor
              config={config}
              product={product}
              store={props.store}
              richTextFn={richTextFn}
              container={container}
              tableOfContentContainer={tableOfContentContainer}
              scrollPosition={{
                tableOfContent: store.rememberScrollPosition.tableOfContentScrollPosition,
                editor: store.rememberScrollPosition.editorScrollPosition
              }}
            />
          )}
        </ProductErrors>
      );
    } else {
      return <Spinner withBackground />;
    }

    return null;
  });
};

interface EditorProps {
  product: IProduct;
  config: AppConfig;
  store: IProductEditorStore;
  richTextFn: RichTextFn;
  container: React.RefObject<HTMLDivElement>;
  tableOfContentContainer: React.RefObject<HTMLDivElement>;
  scrollPosition: ScrollPosition;
}

// todo please do not change this to FC component.
@observer
class Editor extends React.Component<EditorProps> {
  render() {
    const { hasSufficiencyLicence } = this.props.config;
    const { product, ungroupedSection, sections, isUpdatingSufficieny, isSaving, isProductLoading, form, isProductRecalculating } = this.props.store;
    const { title, stepId, path, approvalState, currentRevision, approvalDate, lastUpdateDate } = product as any;

    const sectionsWithUngroupedSection = sections
      ? (ungroupedSection && ungroupedSection.members.length > 0 && sections.concat({ ...ungroupedSection, order: 0, isEmpty: false })) || sections
      : (ungroupedSection && [{ ...ungroupedSection, order: 0, isEmpty: false }]) || [];
    const isLoading = (form && form.revision > -1 === false) || isProductLoading;
    return (
      <>
        {isLoading && <Overlay withTransparentBackground />}
        <ProductSummary
          title={title}
          id={stepId}
          path={path}
          approvalState={approvalState}
          currentRevision={currentRevision}
          approvalDate={approvalDate}
          lastUpdateDate={lastUpdateDate}
          isUpdating={isUpdatingSufficieny}
          hasSufficiencyLicence={hasSufficiencyLicence}
        />
        {
          <RichTextProvider richTextFn={this.props.richTextFn} disableMenu={true}>
            <SectionRef.Provider value={this.props.container}>
              <TableOfContentRef.Provider value={this.props.tableOfContentContainer}>
                {form && form.revision > -1 && (
                  <ProductAttributesEditor
                    id={stepId}
                    sections={sectionsWithUngroupedSection}
                    onNodeUpdate={this.props.store.editClient.nodeUpdate}
                    productEditorStore={this.props.store}
                    product={this.props.product}
                    // refreshing value component state
                    key={this.props.store.isProductLoading ? 0 : this.props.store.updatesCounter}
                    scrollPos={this.props.scrollPosition}
                    isProductReloading={isProductLoading}
                  />
                )}
              </TableOfContentRef.Provider>
            </SectionRef.Provider>
          </RichTextProvider>
        }
      </>
    );
  }
}
