import { flatten, uniq } from "lodash";
import { observable, computed, action, reaction, IReactionDisposer, IComputedValue } from "mobx";
import { computedAsync } from "computed-async-mobx";
import { Severity } from "@stibo/value-components";

import { UNGROUPED_ATTRIBUTE_SECTION, IProduct } from "../Product/types";
import { SectionableProductStore } from "../Sections/SectionableProductStore";
import { ISection, Members, IUngroupedSection } from "../Sections/types";
import { IEditClient } from "./client/client";
import { SingleSectionStore } from "../Sections/SingleSection";
import { BridgeCallbacksProps } from "../ProductDetailsScreen";
import { Form } from "./formStore";
import { ErrorList } from "./client/types";

export interface ValidationResult {
  message: string;
  severity: Severity;
}

export interface IProductEditorStore {
  rememberScrollPosition: RememberScrollPosition;
  updatesCounter: number;
  isUpdatingSufficieny: boolean;
  isUpdatingNode: boolean;
  sections: ISection[] | undefined;
  members: Array<Members> | undefined;
  ungroupedSection?: IUngroupedSection;
  // isEmptyProduct: boolean;
  isProductLoading: boolean;
  product?: IProduct;
  // isProductFound: boolean;
  isProductRecalculating: boolean;
  editClient: IEditClient;
  onEditSuccess: () => void;
  isSectionable: boolean;
  onFormDirty: (val: boolean) => void;
  onEditClosed: () => void;
  onSave: () => void;
  onSaveAndClose: () => void;
  isSaving: boolean;
  form: Form | undefined; // todo change this to interface instead of class
}

export interface ProductEditorStoreProps {
  editClient: IEditClient;
  bridgeCallbacks?: BridgeCallbacksProps;
  onEditClosed: () => void;
  product: IProduct | undefined;
}

export class ProductEditorStore implements IProductEditorStore {
  editClient: IEditClient;
  @observable updatesCounter = 0;
  bridgeCallbacks?: BridgeCallbacksProps;
  disposers: IReactionDisposer[] = [];
  @observable shadowForm?: Form = undefined;
  @observable calculateSufficiency: any = undefined;
  @observable isUpdatingNode: boolean = false;
  @observable isSaving: boolean = false;

  constructor(private props: ProductEditorStoreProps) {
    this.editClient = props.editClient;
    this.bridgeCallbacks = props.bridgeCallbacks;

    this.disposers.push(
      reaction(
        () => this.form && this.form.isDirty,
        () => {
          this.form && this.onFormDirty(this.form.isDirty);
        }
      )
    );
  }

  @computed get product(): IProduct | undefined {
    return this.productShadow.get();
  }

  productShadow: IComputedValue<IProduct | undefined> = computed(
    () => {
      const trigger = this.props.product && this.props.product.productForEditor && this.props.product.productForEditor.updateCounter;
      return this.props.product && this.props.product.productForEditor;
    },
    {
      equals: (a: IProduct | undefined, b: IProduct | undefined) => {
        if (a && b) {
          return a.updateCounter === b.updateCounter;
        } else {
          return false;
        }
      }
    }
  );

  @action
  onEditClosed = () => {
    (this.bridgeCallbacks && this.bridgeCallbacks.onSave && this.bridgeCallbacks.onSave()) || console.log("No callback: onSave!");
    this.onFormDirty(false);
    this.disposers.forEach(disposer => disposer());
    this.props.onEditClosed();
  };

  @action
  onSaveAndClose = () => {
    if (this.form!.canSave) {
      this.editClient.nodeUpdate({ values: this.form!.getDirtyFieldsValuesToSave() }).then(result => {
        if (this.hasError(result)) return;
        this.onEditClosed();
        (this.bridgeCallbacks && this.bridgeCallbacks.onSave && this.bridgeCallbacks.onSave()) || console.log("No callback: onSave!");
      });
    }
  };

  @action
  onProductReseted = () => {};

  // todo this could be optimized in terms of usability - no reload
  @action
  onSave = () => {
    this.rememberScrollPosition.setTableOfContentScrollPosition();
    this.rememberScrollPosition.setEditorScrollPosition();
    if (this.form!.canSave) {
      this.isSaving = true;
      this.editClient.nodeUpdate({ values: this.form!.getDirtyFieldsValuesToSave() }).then(result => {
        if (result && result.data && !result.data.loading) {
          if (this.hasError(result)) return;

          this.isUpdatingNode = false;
          this.editClient.fetchProduct().then(response => {
            this.product && this.product.reset && this.product.reset(response.data.product, this.onProductReseted);
            this.isSaving = false;
            this.onEditSuccess();
          });
          this.calculateSufficiency = computedAsync(undefined, () => {
            return this.editClient.calculateSufficiency();
          });
        }
      });
    }
  };

  hasError = (result): boolean => {
    if (result.errors) {
      const errors = result.errors.map(err => err.message).join(", ");
      this.bridgeCallbacks ? this.bridgeCallbacks.showAlert("error", "Update failed:", errors) : console.error("Update failed: ", errors);
      return true;
    }
    if (((result.data as any).updateNode as ErrorList).errors) {
      const errors = ((result.data as any).updateNode as ErrorList).errors.join(", ");
      this.bridgeCallbacks ? this.bridgeCallbacks.showAlert("error", "Update failed:", errors) : console.error("Update failed: ", errors);
      return true;
    }
    return false;
  };

  @computed
  get form() {
    return this.formShadow.get();
  }

  formShadow: IComputedValue<Form | undefined> = computed(
    () => {
      if (this.product) {
        return new Form(this.editClient, this.onFormDirty, this.product);
      }
      return undefined;
    },
    {
      equals: (a: Form | undefined, b: Form | undefined) => {
        if (a && b) {
          return a.revision === b.revision;
        } else {
          return false;
        }
      }
    }
  );

  onFormDirty = (val: boolean) => {
    (this.bridgeCallbacks && this.bridgeCallbacks.setDirty && this.bridgeCallbacks.setDirty(val)) || console.log("No callback: setDirty!", val);
  };

  @computed
  get isUpdatingSufficieny(): boolean {
    return this.calculateSufficiency ? this.calculateSufficiency.busy : false;
  }

  @computed
  get isProductLoading() {
    return this.isSaving === true ? this.isSaving : this.product && this.product.isProcessing ? true : false;
  }

  @computed get isProductRecalculating() {
    if (this.isProductLoading) {
      if (this.product) {
        return this.product.updateCounter > 0 ? true : false;
      }
      return true;
    } else {
      return false;
    }
  }

  @action
  onEditSuccess() {
    this.updatesCounter = ++this.updatesCounter;
  }

  @computed
  get isSectionable() {
    if (this.product) {
      const { attributeGroups } = this.product;
      const uniqSectionNames = uniq(attributeGroups.map(group => group.section.label));

      if (uniqSectionNames.indexOf(UNGROUPED_ATTRIBUTE_SECTION.label) > -1) {
        return uniqSectionNames.length > 1;
      }

      return uniqSectionNames.length > 0;
    }

    return false;
  }

  @computed
  get sectionableDetailStore() {
    if (this.product) {
      const { attributeGroups, primaryImage, productImages } = this.product;
      return this.isSectionable ? new SectionableProductStore({ attributeGroups, primaryImage, productImages }) : undefined;
    }
    return undefined;
  }

  @computed
  get sections() {
    return (this.sectionableDetailStore && this.sectionableDetailStore.sortedSections.filter(section => !section.isEmpty)) || [];
  }

  @computed
  get ungroupedSection() {
    return this.sectionableDetailStore && this.sectionableDetailStore.ungroupedSection;
  }

  @computed
  get members() {
    return this.isSectionable ? [] : this.singleSectionMemberWithImages();
  }

  @computed
  get isEmptyProduct(): boolean {
    if (this.product) {
      const { primaryImage, attributeGroups } = this.product;

      const isWithoutPrimaryImage = primaryImage ? false : true;
      const isEmpty = attributeGroups.length === 0 || !attributeGroups.some(group => group.hasAttributes());

      return isEmpty ? isEmpty && isWithoutPrimaryImage : false;
    }
    return true;
  }

  private singleSectionMemberWithImages(): Array<Members> {
    if (this.product) {
      const { topAttributeGroup, primaryImage, productImages } = this.product;

      const singleSection = new SingleSectionStore({
        name: "",
        order: 0,
        attributeGroups: flatten([
          topAttributeGroup && topAttributeGroup.hasAttributes() ? topAttributeGroup : undefined,
          topAttributeGroup && topAttributeGroup.childGroups
        ]).filter(group => group)
      });
      singleSection.createCarousel(primaryImage, productImages);
      return singleSection.members;
    }
    return [];
  }

  rememberScrollPosition = new RememberScrollPosition();
}

class RememberScrollPosition {
  tableOfContentContainer: any;
  editorContainer: any;
  tableOfContentScrollPosition: number = 0;
  editorScrollPosition: number = 0;
  constructor() {
    (window as any).rememberScrollPosition = this;
  }

  setTableOfContentScrollPosition = () => {
    this.tableOfContentScrollPosition = this.tableOfContentContainer.current.scrollTop;
  };

  setEditorScrollPosition = () => {
    this.editorScrollPosition = this.editorContainer.current.scrollTop;
  };

  setTableOfContentContainer = container => {
    this.tableOfContentContainer = container;
  };

  setEditorContainer = container => {
    this.editorContainer = container;
  };
}
