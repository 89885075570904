import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { useTranslation } from "tim-bridge";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import {SeverityLevel, WithSeverityLevel} from "../../graphql/query/types";
import SufficiencyMessage from "./panel/SufficiencyMessage";
import {MessageHolders} from "../screen/ProductDetailsScreen/ProductSummary/graphql/types";

export type SufficiencyIndicatorClasses = "container" | "indicator" | "success" | "warning" | "error"  | "messageSection";

const styles = (stiboTheme): StyleRules<SufficiencyIndicatorClasses> =>
  createStyles({
    container: {
      display: "inline-block",
      color: stiboTheme.palette.black[300],
      whiteSpace: "nowrap"
    },
    indicator: {
      color: stiboTheme.colors.white,
      padding: "2px 7px 2px 7px",
      borderRadius: "2px"
    },
    success: {
      backgroundColor: stiboTheme.palette.success[500]
    },
    warning: {
      backgroundColor: stiboTheme.palette.warning[700]
    },
    error: {
      backgroundColor: stiboTheme.palette.danger[500]
    },
    messageSection: {
      paddingTop: "20px"
    }
  });

interface SufficiencyIndicatorProps extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyIndicatorClasses> {
  severityLevel: SeverityLevel;
  title: string;
  score: string;
  showAsBoolean?: boolean;
  "data-qa"?: string;
  messageHolders?: MessageHolders[];
}

const SufficiencyIndicator: React.FC<SufficiencyIndicatorProps & WithStiboStyles<SufficiencyIndicatorClasses>> = props => {
  const { severityLevel, title, score, showAsBoolean, className: classNameProp, classes, "data-qa": dataQa, messageHolders, ...rest } = props;

  const { t } = useTranslation();

  const className = classNames(classes.container, classNameProp);
  const configuration = classNames(
    classes.indicator,
    {
      [classes.success]: severityLevel === SeverityLevel.Success
    },
    {
      [classes.warning]: severityLevel === SeverityLevel.Warning
    },
    {
      [classes.error]: severityLevel === SeverityLevel.Error
    }
  );

  let sufficiency = score;
  if (showAsBoolean) {
    switch (severityLevel) {
      case SeverityLevel.Success:
        sufficiency = `${t("sufficiencyIndicator.success", {
          defaultValue: "Success"
        })}`;
        break;
      case SeverityLevel.Warning:
        sufficiency = `${t("sufficiencyIndicator.warning", {
          defaultValue: "Warning"
        })}`;
        break;
      case SeverityLevel.Error:
        sufficiency = `${t("sufficiencyIndicator.error", {
          defaultValue: "Error"
        })}`;
        break;
    }
  }
  let messageList: JSX.Element[] = [];
  if (messageHolders) {
    messageHolders.forEach((holder, outerIndex) => {
      holder.messages.forEach((info, innerIndex) => {
        const {severityLevel: messageSeverityLevel, message} = info;
        messageList.push(<SufficiencyMessage key={`message-${outerIndex}-${innerIndex}`}
                                             severityLevel={messageSeverityLevel} message={message}/>);
      });
    });
    messageList = messageList.sort((a, b) => compareSeverityLevel(a.props, b.props));
  }

  return (
      <span>
    <span className={className} data-qa={dataQa || "sufficiency-indicator"} {...rest}>
      {title} <span className={configuration}>{sufficiency}</span>
    </span>
        {messageList.length > 0 && (
            <div className={classes.messageSection} data-qa="sufficiency-panel-messages">
              {messageList}
            </div>
        )}
  </span>
  );
};

export const compareSeverityLevel = (a: WithSeverityLevel, b: WithSeverityLevel) => {
  if (a.severityLevel === SeverityLevel.Error) return -1;
  if (b.severityLevel === SeverityLevel.Error) return 1;
  if (a.severityLevel === SeverityLevel.Warning) return -1;
  if (b.severityLevel === SeverityLevel.Warning) return 1;
  if (!a) return 1;
  if (!b) return -1;
  return 0;
};

export default withStiboStyles(styles)(SufficiencyIndicator);
